import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useLanguage } from "../hooks/useLanguage"
import ContactForm from "./contactForm"
import ContactMethods from "./contactInfo"
import { H2 } from "./fonts"

const Contact = ({ className }) => {
    const { switchLanguage } = useLanguage()
    const { contact } = useStaticQuery(graphql`
        query ContactComponent {
            contact: datoCmsContactPage {
                formTitle
                formTitleJp
                methodTitle
                methodTitleJp
            }
        }
    `)
    return (
        <div className='contact p30 pl60 pr60 grid grid-cols-2 gap-x-30 m-grid-cols-1 t-flex-reverse t-grid-cols-1 m-p20 m-gap-y-100 pt160 pb160 max-1600 ma m-flex-reverse t-flex-reverse m-pt80 m-pb0 '>
            <div>
                <H2 className='mb60 m-mb40 m0 t-mt40'>{switchLanguage(contact.methodTitleJp, contact.methodTitle)}</H2>
                <ContactMethods />
            </div>
            <div>
                <H2 className='mb60 m0'>{switchLanguage(contact.formTitleJp, contact.formTitle)}</H2>
                <ContactForm />
            </div>
        </div>
    )
}

export default Contact
