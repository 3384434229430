import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone'
function getValidator(type, validate) {

    if (validate) {
        return { validate }
    }

    const validators = {
        'text': {},
        'email': (value) => isEmail(value),
        'phone': (value) => isMobilePhone(value)
    }

    return validators[type]
}

export default getValidator