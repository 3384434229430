import { graphql } from "gatsby"
import React from "react"
import ChangeHeaderInView from "../components/changeHeaderinView"
import ContactForm from "../components/contact"
import HeroBanner from "../components/heroBanner"
import Layout from "../components/layout"
import PageNavigation from "../components/pageNavigation"
import Seo from "../components/seo"
import { COLOR_STATES } from "../context/menuContext"
import { useLanguage } from "../hooks/useLanguage"

export default function Contact({ data }) {
    data = data.contact
    const { switchLanguage } = useLanguage()
    return (
        <Layout footer={false}>
            <Seo title='Contact' />
            <HeroBanner mobileImage={data.mobileHeroImage} mobileVideo={data.mobileHeroVideoUrl} title={switchLanguage(data.heroTitleJp, data.heroTitle)} video={data.heroVideoUrl} image={data.heroImage} />
            <ChangeHeaderInView threshold={0.1} color={COLOR_STATES.DARK_WHITE_BG}>
                <PageNavigation className='t-hide' background='white' />
                <div className='bg-white'>
                    <ContactForm />
                </div>
            </ChangeHeaderInView>
        </Layout>
    )
}

export const query = graphql`
    query Contact {
        pageData: datoCmsContactPage {
            subtitle
            subtitleJp
        }
        contact: datoCmsContactPage {
            mobileHeroVideoUrl
            mobileHeroImage {
                gatsbyImageData
            }
            heroTitle
            heroTitleJp
            heroVideoUrl
            heroImage {
                gatsbyImageData
            }
        }
    }
`
