import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ReactMarkdown from "react-markdown"
import { useLanguage } from "../hooks/useLanguage"
import { P } from "./fonts"
import ReadMore from "./readMore"
import ConditionallyRender from "./utils/conditionallyRender"

export default function ContactMethods() {
    const {
        data: { contactMethods },
    } = useStaticQuery(graphql`
        query MyQuery {
            data: datoCmsContactPage {
                contactMethods {
                    name
                    info
                    linkText
                    nameJp
                    infoJp
                    linkTextJp
                    linkUrl
                    icon {
                        url
                    }
                }
            }
        }
    `)

    return (
        <div className='grid grid-cols-2  grid-rows-2 grid-flow-column gap-x-30 gap-y-30 m-flex m-wrap m-bt1 m-pt30 '>
            {contactMethods.map((contactMethod, index) => {
                return <ContactCard key={index} contactForm={contactMethod} />
            })}
        </div>
    )
}

function ContactCard({ contactForm }) {
    const { switchLanguage } = useLanguage()
    return (
        <div className='m-100 m-bb1 m-pb30'>
            <img alt='' src={contactForm.icon.url} className=' mb10' />
            <P className='m0'>{switchLanguage(contactForm.nameJp, contactForm.name)}</P>
            <P className='m0 mb5 text-grey'>
                <ReactMarkdown className='markdown-contact'>{switchLanguage(contactForm.infoJp, contactForm.info)}</ReactMarkdown>
            </P>
            <ConditionallyRender when={contactForm.linkText}>
                <ContactLink contactForm={contactForm} />
            </ConditionallyRender>
        </div>
    )
}

function ContactLink({ contactForm }) {
    const { switchLanguage } = useLanguage()
    return (
        <ReadMore as={P} href={contactForm.linkUrl}>
            {switchLanguage(contactForm.linkTextJp, contactForm.linkText)}
        </ReadMore>
    )
}
