import classNames from "classnames"
import { graphql, useStaticQuery } from "gatsby"
import React, { memo, useCallback, useMemo, useState } from "react"
import { FormProvider, useForm, useFormContext, useFormState } from "react-hook-form"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { useLanguage } from "../hooks/useLanguage"
import getValidator from "../utils/validator"
import { P } from "./fonts"

export default function ContactForm() {
    const [currentView, setCurrentView] = useState("form")
    const [error, setError] = useState(``)

    const onSubmit = useCallback(async (_, e) => {
        try {
            e.preventDefault()
            const form = e.target
            let formData = new FormData(form)
            const res = await fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: new URLSearchParams(formData).toString(),
            })
            setCurrentView("submitted")
            if (res.status >= 300) {
                throw new Error(`Failed request ${res.status}`)
            }
        } catch (e) {
            setError(`We've encountered an error, please reload the page or contact us another way.`)
            console.error(e)
        }
    }, [])

    const { contact } = useStaticQuery(graphql`
        query Options {
            contact: datoCmsContactPage {
                submissionMessage
                submissionMessageJp
                submitButtonText
                submitButtonTextJp
                formFields {
                    id
                    title
                    titleJp
                }
                enquiryTypes {
                    id
                    title
                    titleJp
                }
            }
        }
    `)

    const renderCurrentView = () => {
        if (currentView === "submitted") return <Submitted contact={contact} />
        return <Form onSubmit={onSubmit} error={error} contact={contact} />
    }

    return (
        <SwitchTransition>
            <CSSTransition key={currentView} timeout={500} classNames='fade'>
                {renderCurrentView}
            </CSSTransition>
        </SwitchTransition>
    )
}

const Submitted = memo(({ contact }) => {
    const { switchLanguage } = useLanguage()
    return <P className='mt20 mb20'>{switchLanguage(contact.submissionMessageJp, contact.submissionMessage)}</P>
})

const validateEnquiryType = v => {
    return true
}

const Form = memo(({ error, onSubmit, contact }) => {
    const methods = useForm({ mode: "onTouched" })

    const { switchLanguage } = useLanguage()

    const options = contact.enquiryTypes.map(type => switchLanguage(type.titleJp, type.title))
    const fields = contact.formFields

    return (
        <FormProvider {...methods}>
            <form name='contact' method='POST' data-netlify='true' onSubmit={methods.handleSubmit(onSubmit)} className='grid grid-cols-2 gap-y-30 gap-x-30 m-flex m-wrap m-gap-y-20'>
                <input type='hidden' name='form-name' value='contact' />
                <Input name={switchLanguage(fields[0].titleJp, fields[0].title)} type='text' />
                <Input name={switchLanguage(fields[1].titleJp, fields[1].title)} type='text' />
                <Input name={switchLanguage(fields[2].titleJp, fields[2].title)} type='email' />
                <Input name={switchLanguage(fields[3].titleJp, fields[3].title)} type='phone' />
                <Input name={switchLanguage(fields[4].titleJp, fields[4].title)} type='text' className='col-span-2 m-col-span-unset m-mb10' />
                <DropDown validate={validateEnquiryType} options={options} name={switchLanguage(fields[5].titleJp, fields[5].title)} type='dropdown' className='m-mb10' />
                <TextArea name={switchLanguage(fields[6].titleJp, fields[6].title)} type='text' className='mb30 m-mb0 m-mt0 m-col-span-unset col-span-2 m-mb10 m-100 m-flex m-wrap m-pt25 m-pt0' required={false} />
                <div className='col-span-2 m-col-span-unset m-mb60 m-100  m-pt10 '>
                    <SubmitButton className='w-100' title={switchLanguage(contact.submitButtonTextJp, contact.submitButtonText)} />
                    {error ? <P style={{ color: "red" }}>{error}</P> : null}
                </div>
            </form>
        </FormProvider>
    )
})

const SubmitButton = memo(({ title }) => {
    return (
        <button className='button menu--button hover--green dark max-200 w-100 m-max-unset m-mwa'>
            <P>{title}</P>
        </button>
    )
})

const DropDown = ({ name, className, type, required = true, options, validate, inputClass }) => {
    const { register } = useFormContext()
    const {
        errors: { [name]: error },
        touchedFields: { [name]: isTouched },
    } = useFormState()

    const validator = useMemo(() => {
        return getValidator(type, validate)
    }, [type, validate])

    const inputOptions = useMemo(() => {
        return { required, ...validator, ...options }
    }, [validator, options, required])

    return (
        <div className='select--wrapper w-100 col-span-2 m-col-span-unset'>
            <select {...register(name, inputOptions)} className={classNames("input", "input-select match-body pt30 ", "p", className, { error: error && isTouched })}>
                <option selected={true} disabled={true}>
                    {name}
                </option>
                {options.map(option => {
                    return <option value={option}>{option}</option>
                })}
            </select>
        </div>
    )
}

const Input = memo(({ name, className, inputClass, type, required = true, options, validate }) => {
    const { register } = useFormContext()
    const {
        errors: { [name]: error },
        touchedFields: { [name]: isTouched },
    } = useFormState()

    const validator = useMemo(() => {
        return getValidator(type, validate)
    }, [type, validate])

    const inputOptions = useMemo(() => {
        return { required, ...{ validate: validator }, ...options }
    }, [validator, options, required])

    const [open, setOpen] = useState(false)

    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [setOpen])

    return (
        <div className={className + " m-100"}>
            <P className={classNames("m0", "input-title", { "move-up": open })}>{name}</P>
            <input onFocus={handleOpen} className={classNames("input", "input-text", inputClass, { error: error && isTouched })} {...register(name, inputOptions)} />
        </div>
    )
})

const TextArea = memo(({ name, className, inputClass, type, required = true, options, validate }) => {
    const { register } = useFormContext()

    const validator = useMemo(() => {
        return getValidator(type, validate)
    }, [type, validate])

    const inputOptions = useMemo(() => {
        return { required, ...validator, ...options }
    }, [validator, options, required])

    return (
        <div className={className}>
            <P className='m0 mt30'>{name}</P>
            <textarea className={classNames("text-area m-100", "input-text", inputClass)} {...register(name, inputOptions)} />
        </div>
    )
})
